import i18next from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18next
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    returnObjects: true,
    resources: {
      en: {
        translation: {
          back: "Back",
          step: "Step",
          about: "About",
          "choose-service": "Choose Service",
          "read-more": "Read more",
          "show-less": "Show less",
          "no-services": "No services available",
          "choose-host": "Choose Host",
          "choose-date": "Choose Date",
          "choose-time": "Choose Time",
          selectResource: "Select Resource",
          from: "from",
          mins: "mins",
          minutes: "minutes",
          "mins-range": "{{min}} - {{max}} mins",
          "no-slots": "No slots available",
          "service-details": "Service Details",
          host: "Host",
          where: "Where",
          when: "When",
          "full-name": "Full Name",
          email: "Email",
          "phone-number": "Phone Number",
          "confirm-email-info": "We will send your booking confirmation here.",
          "confirm-number-info":
            "We may verify your entered phone number by sms.",
          "terms-and-conditions":
            "I agree to the <0>privacy policy</0> and <1>terms of service</1> of booking.",
          "total-line": "Total (incl. tax & €{{processingFee}} processing fee)",
          next: "Next",
          continue: "Continue",
          "choose-payment-method": "Select a payment method.",
          "pay-at-venue": "Pay at venue",
          tikkie: "Tikkie",
          ideal: "iDEAL",
          "apple-pay": "Apple Pay",
          "google-pay": "Google Pay",
          card: "Card",
          "verify-phone": "Verify Phone",
          "verify-phone-number": "Verify Phone Number",
          "verify-phone-number-text":
            "Please enter the 4 digit code sent to <1>{{number}}</1> through SMS",
          "did-not-receive-code": "Didn't receive the code?",
          "resend-sms": "Resend SMS",
          "phone-number-verified": "Phone number verified",
          "payment-details": "Payment Details",
          "processing-fee": "Processing Fee",
          "total-vat": "Total (inc. VAT)",
          "your-details": "Your Details",
          yes: "Yes",
          no: "No",
          free: "Free",
          "free-service": "Free Service",
          "pay-later": "Pay later",
          "pay-at-venue-option-info":
            "This option requires you to verify your mobile number via a verification code sent your mobile",
          ok: "Ok",
          cancel: "Cancel",
          "review-and-pay": "Review & Pay",
          change: "Change",
          pay: "Pay",
          "pay-and-confirm": "Pay and confirm booking",
          "waiting-payment-confirmation": "Waiting for payment confirmation",
          "tikkie-did-not-open": "Tikkie did not open?",
          "click-here": "Click here",
          "try-again": "Please try again",
          "order-confirmation": "Order confirmation",
          "payment-due": "Payment(s) due",
          "payment-confirmed": "Payment confirmed",
          "host-may-inquire-info":
            "Host may send you a payment request before start of the service",
          "order-confirmed": "Your order is confirmed!",
          "booking-details-sent-to": "Your Booking Details are sent to",
          "book-again": "Book again",
          errors: {
            required: "{{field}} is required",
            invalid: "{{field}} is invalid",
            "terms-and-conditions": "Please agree to the terms and conditions",
            select: "Please select a {{field}}",
            "reservation-failed": "Reservation failed.",
            "payment-method": "Please select a payment method",
            ideal:
              "You have selected iDEAL as payment method. Please select a bank as well.",
            "another-method":
              "Something went wrong. Please try another payment method.",
            server: "Something went wrong.",
            "not-found": "Not Found",
            "tikkie-link-gen":
              "An error occurred while generating payment link. Please try again later.",
            "payment-fail": "Payment failed",
            missing: "{{field}} is missing.",
            payment_failed: "Payment failed",
            payment_requested: "Payment requested",
            payment_success: "Payment successful",
            payment_canceled: "Payment canceled",
            "payment-unsupported":
              "{{type}} Pay is not supported on this device. Please select another payment method.",
            "payment-card-required":
              "{{type}} Pay is not supported on this device. Please add a card to your {{type}} Pay account and try again.",
          },
        },
      },
      nl: {
        translation: {
          back: "Terug",
          step: "Stap",
          about: "Over",
          "choose-service": "Kies service",
          "read-more": "Lees meer",
          "show-less": "Toon minder",
          "no-services": "Geen services beschikbaar",
          "choose-host": "Kies uw Host",
          "choose-date": "Kies Datum",
          "choose-time": "Kies Tijd",
          selectResource: "Selecteer Bron",
          from: "vanaf",
          mins: "minuten",
          minutes: "minuten",
          "mins-range": "{{min}} - {{max}} minuten",
          "no-slots": "Geen beschikbare tijdsloten",
          "service-details": "Service gegevens",
          host: "Host",
          where: "Waar",
          when: "Wanneer",
          "full-name": "Uw naam",
          email: "E-mail",
          "phone-number": "Telefoonnummer",
          "confirm-email-info": "We sturen uw boekings bevestiging hierheen.",
          "confirm-number-info":
            "We kunnen uw telefoonnummer verifiëren via sms.",
          "terms-and-conditions":
            "Ik ga akkoord met het <0>privacybeleid</0> en de <1>servicevoorwaarden</1> van de boeking.",
          "total-line":
            "Totaal (incl. btw & €{{processingFee}} verwerkingskosten)",
          next: "Volgende",
          continue: "Doorgaan",
          "choose-payment-method": "Selecteer een betaalmethode.",
          "pay-at-venue": "Betalen op locatie",
          tikkie: "Tikkie",
          ideal: "iDEAL",
          "apple-pay": "Apple Pay",
          "google-pay": "Google Pay",
          card: "Kaart",
          "verify-phone": "Verifieer telefoon",
          "verify-phone-number": "Verifieer telefoonnummer",
          "verify-phone-number-text":
            "Voer de 4-cijferige code in die naar <1>{{number}}</1> is verzonden via sms",
          "did-not-receive-code": "Code niet ontvangen?",
          "resend-sms": "Sms opnieuw verzenden",
          "phone-number-verified": "Telefoonnummer geverifieerd",
          "payment-details": "Betalingsdetails",
          "processing-fee": "Verwerkingskosten",
          "total-vat": "Totaal (incl. btw)",
          "your-details": "Uw gegevens",
          yes: "Ja",
          no: "Nee",
          free: "Gratis",
          "free-service": "Gratis service",
          "pay-later": "Later betalen",
          "pay-at-venue-option-info":
            "Met deze optie moet u uw mobiele nummer verifiëren via een verificatiecode die naar uw telefoon wordt verzonden.",
          ok: "Oké",
          cancel: "Annuleren",
          "review-and-pay": "Controleer & betaal",
          change: "Wijzigen",
          pay: "Betalen",
          "pay-and-confirm": "Betaal en bevestig boeking",
          "waiting-payment-confirmation": "Wachten op betalingsbevestiging",
          "tikkie-did-not-open": "Tikkie niet geopend?",
          "click-here": "Klik hier",
          "try-again": "Probeer het opnieuw",
          "order-confirmation": "Bestelbevestiging",
          "payment-due": "Betaling verschuldigd",
          "payment-confirmed": "Betaling bevestigd",
          "host-may-inquire-info":
            "De gastheer kan u een betalingsverzoek sturen voor aanvang van de service.",
          "order-confirmed": "Uw bestelling is bevestigd!",
          "booking-details-sent-to": "Uw boekingsgegevens zijn verzonden naar",
          "book-again": "Boek opnieuw",
          errors: {
            required: "{{field}} is verplicht",
            invalid: "{{field}} is ongeldig",
            "terms-and-conditions": "Accepteer de algemene voorwaarden",
            select: "Selecteer een {{field}}",
            "reservation-failed": "Reservering mislukt.",
            "payment-method": "Selecteer een betaalmethode",
            ideal:
              "U heeft iDEAL geselecteerd als betaalmethode. Selecteer ook een bank.",
            "another-method":
              "Er is iets misgegaan. Probeer een andere betaalmethode.",
            server: "Er is iets misgegaan.",
            "not-found": "Niet gevonden",
            "tikkie-link-gen":
              "Er is een fout opgetreden bij het genereren van de betalingslink. Probeer het later opnieuw.",
            "payment-fail": "Betaling mislukt",
            missing: "{{field}} ontbreekt.",
            payment_failed: "Betaling mislukt",
            payment_requested: "Betaling aangevraagd",
            payment_success: "Betaling geslaagd",
            payment_canceled: "Betaling geannuleerd",
            "payment-unsupported":
              "{{type}} Pay wordt niet ondersteund op dit apparaat. Selecteer een andere betaalmethode.",
            "payment-card-required":
              "{{type}} Pay wordt niet ondersteund op dit apparaat. Voeg een kaart toe aan uw {{type}} Pay-account en probeer opnieuw.",
          },
        },
      },
    },
  });
