import React, { useState, forwardRef, useEffect } from "react";
import { Us, Nl } from "react-flags-select";
import { Group, Select, Text, createStyles, rem } from "@mantine/core";
import { useTranslation } from "react-i18next";

const languages = [
  {
    value: "en",
    label: "EN",
    icon: <Us fontSize={rem(22)} />,
  },
  {
    value: "nl",
    label: "NL",
    icon: <Nl fontSize={rem(22)} />,
  },
];

const useStyles = createStyles((theme) => ({
  selectRoot: {
    maxWidth: rem(100),
    [`@media (max-width: ${rem(375)})`]: {
      maxWidth: rem(120), // Make select full width on smaller screens
    },
    [`@media (max-width: ${rem(390)})`]: {
      maxWidth: rem(110), // Make select full width on smaller screens
    },
  },
  selectInput: {
    borderColor: "#e0e0e0",
    backgroundColor: "#f1f2f4",
    borderRadius: 4,
    ":focus-within": {
      borderColor: "#CCCCCC",
      backgroundColor: "#e0e0e0",
    },
    [`@media (max-width: ${rem(375)})`]: {
      width: "100%", // Make input full width on smaller screens
      fontSize: rem(10),
    },
  },
  dropdown: {
    backgroundColor: "#f1f2f4", // Set the background color of the dropdown
    borderRadius: 4,
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)", // Optional: Add subtle shadow for better visual separation
  },
  selectItem: {
    backgroundColor: "#f1f2f4", // Background color for each dropdown item
    ":hover": {
      backgroundColor: "#d0d0d0", // Darken the background when hovered
    },
    borderRadius: 4,
  },
}));

const SelectItem = forwardRef(({ icon, label, ...others }, ref) => (
  <div ref={ref} {...others}>
    <Group>
      {icon}
      <div>
        <Text size="xs">{label}</Text>
      </div>
    </Group>
  </div>
));

export default function LanguageSelector() {
  const { classes } = useStyles();
  const { i18n } = useTranslation();

  // Set the initial language based on the current language in i18n
  const [language, setLanguage] = useState(i18n.language || "en");

  useEffect(() => {
    // Update the language when the i18n language changes
    setLanguage(i18n.language || "en");
  }, [i18n.language]);

  const onChangeLang = (lang) => {
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  return (
    <Select
      data={languages}
      itemComponent={SelectItem}
      value={language}
      onChange={onChangeLang}
      placeholder="Select language"
      classNames={{
        root: classes.selectRoot,
        item: classes.selectItem,
        input: classes.selectInput,
        dropdown: classes.dropdown,
      }}
      icon={languages.find((item) => item.value === language)?.icon}
      transitionProps={{
        transition: "scale-y",
        duration: 80,
        timingFunction: "ease",
      }}
      size="xs"
    />
  );
}
